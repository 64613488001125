import { MergeTypes } from '@/views/modules/Versioning/MergeVersion/@constants/merge-version.const'

import { PeriodFactory } from './period.factory'
import { VersionFactory } from './version.factory'

export const MergeVersionFilterFactory = {
  create() {
    return {
      type: MergeTypes.version,
      periods: PeriodFactory.create(),
      version: VersionFactory.create(),
      indicatorIDs: [],
      productIDs: [],
      channelIDs: [],
      // Merge Type Channel
      channelParam: {
        levelChannel: 1,
        fromChannelID: 0,
        toChannels: []
      },
      // Merge Type Product
      productParam: {
        levelProduct: 1,
        fromProductID: 0,
        toProducts: []
      },
      filterSelection: {}
    }
  }
}
